import React from "react";
import {Select, message} from "antd";
import axios from 'util/Api'
import {parseJSONItem, cleanJSONContent, getListToOptions} from "util/Toolbox";
import joursFeries from "@socialgouv/jours-feries";

const Option = Select.Option;

/////////////////////////////////////////////////////////////////////////////////
/////**************************** GETTER TOOLS *****************************/////
/////////////////////////////////////////////////////////////////////////////////

export const getLibelleStatutCommandeFournisseur = (status) => {
  switch(status) {
    case 1: return 'En création';
    case 2: return 'Validée';
    case 3: return 'Livrée';
    case 4: return 'Annulée';
    default: return '';
  }
}

/////////////////////////////////////////////////////////////////////////////////
/////*********************** LIST GETTER TO OPTIONS ************************/////
/////////////////////////////////////////////////////////////////////////////////
export const getCaractListOptions = async (IDCaracteristique) => {
  let url = '/composant/'+IDCaracteristique+'/caracteristiqueListe';
  return getListToOptions(await getList(url, 'caracteristiqueListe_LIST'), 'IDCaracteristique_Liste', 'Caracteristique_Liste_Libelle');
}



export const getListTypeForfait = () => {
  var listTypeForfait = [];
  listTypeForfait.push(<Option value={'2'} key={''}>{'Forfait illimité voix/data'}</Option>);
  listTypeForfait.push(<Option value={'1'} key={''}>{'Forfait data only'}</Option>);
  return listTypeForfait;
 }

export const getListCreneaux = () => {
  var listCreneaux = [];
  listCreneaux.push(<Option key={5} value={5}>5 min (vente)</Option>);
  listCreneaux.push(<Option key={30} value={30}>30 min</Option>);
  listCreneaux.push(<Option key={60} value={60}>1h</Option>);
  listCreneaux.push(<Option key={90} value={90}>1h30</Option>);
  listCreneaux.push(<Option key={120} value={120}>2h</Option>);
  return listCreneaux;
}

export const getListTypesRemise = () => {
  var listTypesRemise = [];
  listTypesRemise.push(<Option value={25} key={25}>{"Prestation"}</Option>);
  listTypesRemise.push(<Option value={26} key={26}>{"Vente"}</Option>);
  return listTypesRemise;
}

export const getListEtatsFacture = () => {
  var list = [];
  list.push(<Option value={1} key={1}>{"Echue"}</Option>);
  list.push(<Option value={2} key={2}>{"Non echue"}</Option>);
  list.push(<Option value={3} key={3}>{"Réglée"}</Option>);
  list.push(<Option value={4} key={4}>{"En cours de prélèvement"}</Option>);
  return list;
}

export const getListTypesSociete = () => {
  var listTypesSociete = [];
  listTypesSociete.push(<Option value={1} key={1}>{'SAS'}</Option>);
  listTypesSociete.push(<Option value={2} key={2}>{'SASU'}</Option>);
  listTypesSociete.push(<Option value={3} key={3}>{'SARL'}</Option>);
  listTypesSociete.push(<Option value={4} key={4}>{'Auto-entrepreneur'}</Option>);
  listTypesSociete.push(<Option value={5} key={5}>{'EURL'}</Option>);
  listTypesSociete.push(<Option value={6} key={6}>{'EARL'}</Option>);

  return listTypesSociete;
}

export const getListCivilite = () => {
  var listCivilite = [];
  listCivilite.push(<Option value={1} key={1}>{'Mr'}</Option>);
  listCivilite.push(<Option value={2} key={2}>{'Mme'}</Option>);
  listCivilite.push(<Option value={3} key={3}>{'Autre'}</Option>);
  return listCivilite;
}

export const getListTypesFacture = () => {
  var list = [];
  list.push(<Option value={1} key={1}>{"Classique"}</Option>);
  list.push(<Option value={2} key={2}>{"Abonnement"}</Option>);
  return list;
}

export const getListEmployes = () => {
  var listEmployes = [];
  listEmployes.push(<Option value={1} key={1}>{"Kévin"}</Option>);
  listEmployes.push(<Option value={2} key={2}>{"Adelin"}</Option>);
  listEmployes.push(<Option value={3} key={3}>{"François"}</Option>);
  listEmployes.push(<Option value={346} key={346}>{"Matthieu"}</Option>);
  listEmployes.push(<Option value={348} key={348}>{"Thomas"}</Option>);
  listEmployes.push(<Option value={356} key={356}>{"Alex"}</Option>);
  listEmployes.push(<Option value={369} key={369}>{"Florian"}</Option>);
  listEmployes.push(<Option value={370} key={370}>{"Sophia"}</Option>);
  listEmployes.push(<Option value={374} key={374}>{"Alexi"}</Option>);
  return listEmployes;
}

/////////////////////////////////////////////////////////////////////////////////
/////*********************** LIST ACTIONS AXIOS GET ************************/////
/////////////////////////////////////////////////////////////////////////////////
const getList = async (url, listType='', list=[]) => {

  await axios.get(url,
  ).then(({data}) => {

    if(listType === '')
      list = data;
    else
      list = list.concat(data[listType]);
  }).catch(function (error) {
    console.log("Error****:", error.message);
  });
  return list;
}

const getListV2 = async (url, params, listType='', list=[]) => {
  await axios.get(url, {
      params
    }
  ).then(({data}) => {

    if(listType === '')
      list = data;
    else
      list = list.concat(data[listType]);
  }).catch(function (error) {
    console.log("Error****:", error.message);
  });
  return list;
}

export const getListModesReglements = (modeReglementList) => {
  let url = '/facture/mode_reglement';
  return getList(url, 'modeReglement_LIST', modeReglementList);
}


export const getListChargesFixesV2 = async () => {
  let url = '/chargefixe/typechargeFixeV2';

  let list = await getList(url);

  list = parseJSONItem(list.typeCharge_LIST, 'children');

  return list;

}


export const getListCodePromo = (codePromoList) => {
  let url = '/intervention/codePromo';
  return getList(url, 'codePromo_LIST', codePromoList);
}


export const getListPays = (paysList) => {
  let url = '/client/pays';
  return getList(url, 'pays_LIST', paysList);
}


export const getListComposants = async (search, offset, IDPiece, IDEtatComposant, IDSite, IsLight) => {
  let url = 'composant/'+offset+'/20/'+IDPiece+'/'+IDEtatComposant+'/'+ IDSite + '/' + (search === '' ? undefined : search)+ '/' + IsLight+ '/composant';
  let list = await getList(url);

  console.log(list)

  //list.composant_LIST.sort( compareSiteComposant );

  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifFournisseur');
  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifClient');

  return list;
}


export const getListComposantMontant = async (search, offset, IDPiece, IDEtatComposant, IDSite, IsLight) => {
  let url = 'composant/'+offset+'/20/'+IDPiece+'/'+IDEtatComposant+'/'+ IDSite + '/' + (search === '' ? undefined : search)+ '/' + IsLight+ '/composantMontant';
  let list = await getList(url);
  return list;
}


export const getListComposantsScan = async (search, offset, IDPiece, IDEtatComposant, IDSite, IsLight) => {
  let url = 'composant/'+offset+'/20/'+IDPiece+'/'+IDEtatComposant+'/'+ IDSite + '/' + (search === '' ? undefined : search)+ '/' + IsLight+ '/composantScan';
  let list = await getList(url);
  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifFournisseur');
  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifClient');

  return list;
}


export const getListComposantsV2 = async (search, offset, IDPiece, IDEtatComposant, IDSite, IsLight) => {
  let url = 'composant/'+offset+'/20/'+IDPiece+'/'+IDEtatComposant+'/'+ IDSite + '/' + (search === '' ? 0 : search)+ '/' + IsLight+ '/composantList';
  let list = await getList(url);

  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifFournisseur');
  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifClient');

  return list;
}


export const getListComposantsLight = async (search, offset, IDPiece, IDEtatComposant, IDSite, IDArticle) => {

  let url = 'composant/'+offset+'/20/'+IDPiece+'/'+IDEtatComposant+'/'+ IDSite + '/' + IDArticle + (search === '' ? '' : '/'+search)+ '/composantLight';

  let list  = await getList(url, 'composant_LIST');
  list      = parseJSONItem(list, 'TarifFournisseur');
  list      = parseJSONItem(list, 'TarifClient');

  return list;
}


export const getListComposantsLightList = async (search, offset, IDPiece, IDEtatComposant, IDSite, composant_LIST) => {

  let url = 'composant/'+offset+'/20/'+IDPiece+'/'+IDEtatComposant+'/'+ IDSite + (search === '' ? '' : '/'+search)+ '/composantLightList';

  let list = await getList(url, 'composant_LIST');
  list = parseJSONItem(list, 'TarifFournisseur');
  list = parseJSONItem(list, 'TarifClient');


  return composant_LIST.concat(list);
}


export const getComposantByGUID = async (GUID) => {
  let url   = 'composant/'+GUID+'/composantByGUID';
  let list  = await getList(url);
  list.composant_LIST = parseJSONItem(list.composant_LIST, 'TarifFournisseur');
  return list;
}


export const getComposantByIDComposant = async (IDComposant) => {
  let url = 'composant/'+IDComposant+'/composantByID';
  let list = await getList(url, 'composant');

  list = parseJSONItem(list, 'TarifFournisseur');
  list = parseJSONItem(list, 'TarifClient');

  return list[0];
}


export const getListTypeCDR = (pe_IDTypeCDR) => {
  let url = 'flotte/'+pe_IDTypeCDR+'/typeCDR';
  return getList(url, 'typeCDR_LIST');
}


export const getListCommandesFournisseur = (offset, IDStatutCommande, IDSite, search, commandesList) => {
  let url = 'commandefournisseur/'+offset+'/20/'+ IDStatutCommande+'/' + IDSite + (search === '' ? '' : '/'+search) + '/commandefournisseur';
  return getList(url, 'commandeFournisseur_LIST', commandesList);
}


export const getListArticlesFournisseur = (IDFournisseur, offset, search, IDPiece, tarifList) => {
  let url = '/fournisseur/'+IDFournisseur+'/'+IDPiece+'/'+offset+'/20'+(search === '' ? '' : '/'+search)+'/tarifFournisseur';
  return getList(url, 'tarifAchat_LIST', tarifList);
}


export const getListArticlesACommander = (IDFournisseur, offset, search, IDPiece, tarifList) => {
  let url = '/fournisseur/'+IDFournisseur+'/'+IDPiece+'/'+offset+'/20'+(search === '' ? '' : '/'+search)+'/tarifFournisseurIntervention';
  return getList(url, 'tarifAchat_LIST', tarifList);
}


export const getStock = (IDPiece, offset, search, IDSite, IDModele, stock_LIST) => {
  let url = '/profile/stock/'+IDPiece+'/'+offset+'/20'+ '/' + IDSite + '/' + IDModele + (search === '' ? '' : '/'+search)+'/stock';
  return getList(url, 'stock_LIST', stock_LIST);
}


export const getListBonsReception = async (currentOffset, bonsReceptionList) => {
  let url = '/bonreception/'+currentOffset+'/20/bonreception';
  let list = parseJSONItem(await getList(url, 'bonReception_LIST'), 'BonReceptionLigneList');
  return bonsReceptionList.concat(list);
}


export const getListBonsReceptionV2 = async (currentOffset, IDSite, search, bonsReceptionList) => {
  let url = '/bonreception/'+currentOffset+'/20/'+ IDSite  +(search === '' ? '' : '/'+search) + '/bonreceptionv2';
  let list = parseJSONItem(await getList(url, 'bonReception_LIST'), 'BonReceptionLigneList');
  return bonsReceptionList.concat(list);
}


export const getListBonsReceptionByIDBonReception = async (IDBonReception) => {
  let url = `/bonreception/${IDBonReception}/bonreceptionbyid`;
  let list = parseJSONItem(await getList(url, 'bonReception_LIST'), 'BonReceptionLigneList');
  return list;
}


export const getListInterventionByIDIntervention = async (IDIntervention) => {
  let url = `/intervention/${IDIntervention}/interventionByIDIntervention`;
  
  let list = parseJSONItem(await getList(url, 'intervention_LIST'), 'TarifList');

  list = parseJSONItem(list, 'ComposantDefectueuxList');
  return list[0];
}







export const getListSavByIDSav = async (IDSAV) => {
  let url = `/sav/${IDSAV}/savByIDSav`;
//  let list = parseJSONItem(await getList(url, 'sav_LIST'), 'TarifList');
  let list = parseJSONItem(await getList(url, 'sav_LIST'), 'CommentaireList');
  list = parseJSONItem(list, 'ComposantList');

  return list[0];
}


export const getnavetteByIDNavette = async (IDNavette) => {
  let url = `/navette/${IDNavette}/navetteByIDNavette`;
  let list = parseJSONItem(await getList(url, 'navette_LIST'), 'NavetteLigneList');
  return list[0];
}


export const getListRecoByIDReco = async (IDReconditionnement) => {
  let url = `/reconditionnement/${IDReconditionnement}/reconditionnementByID`;
  let list = parseJSONItem(await getList(url, 'reconditionnement_LIST'), 'ReconditionnementList');
  return list[0];
}


export const getListRecoByIDRecoV2 = async (IDReconditionnement) => {
  let url = `/reconditionnement/${IDReconditionnement}/reconditionnementByIDV2`;
  let list = parseJSONItem(await getList(url, 'reconditionnement_LIST'), 'ReconditionnementList');
  list = parseJSONItem(list, 'EtapeReconditionnementList');
  return list[0];
}


export const getListDemantelementByID= async (IDDemantelement) => {
  let url = `/demantelement/${IDDemantelement}/demantelementByID`;
  let list = parseJSONItem(await getList(url, 'demantelement_LIST'), 'DemantelementList');

  return list[0];
}


export const getListInventaireByID= async (IDInventaire) => {
  let url = `/inventaire/${IDInventaire}/inventaireByID`;
  let list = parseJSONItem(await getList(url, 'inventaire_LIST'), 'InventaireList');
  return list[0];
}


export const getInventaireRapportByID= async (IDInventaire) => {
  let url = `/inventaire/${IDInventaire}/inventaireRapportByID`;
  let list = await getList(url, 'TypeArticleComposant_LIST');
  return list;
}



export const getListModeles = (IDMarque = 0) => {
  let url = 'profile/'+IDMarque+'/modele';
  return getList(url, 'modele_LIST');
}


export const getListPieceModele = async (IDMarque = 0) => {
  let url = 'demantelement/'+IDMarque+'/demantelementPiece';

  let list = await getList(url, 'piece_LIST');

  list = parseJSONItem(list, 'TarifFournisseur');
  return list
}


export const getTresorerie = async (isForceReload) => {
  let url = `tresorerie/${isForceReload}/tresorerie`;
  let list = await getList(url, 'compteBancaire_LIST');
  return list;
}


export const getVerifBanque = async (IDBanque) => {
  let url = `tresorerie/${IDBanque}/verifBanque`;
  let list = await getList(url, 'url_api');
  return list;
}


export const getAddBanque = async () => {
  let url = `tresorerie/addBank`;
  let list = await getList(url, 'url_api');
  return list;
}



export const getDashbordB2B = async () => {
  let url = `dashboard/dashboardB2B`;
  let list = await getList(url, 'dashboard_LIST');
  return list;
}





export const getDashbordTresorerie = async () => {
  let url = 'tresorerie/dashbordTresorerie';
  let list = await getList(url, 'dashboardTreso_LIST');
  return list;
}



export const getEtatOpportunite = async () => {
  let url = 'intervention/etatOpportunite';
  let list = await getList(url, 'etatOpportunite_LIST');
  return list;
}


export const getEtatReconditionnement = async () => {
  let url = 'reconditionnement/etatReconditionnement';
  let list = await getList(url, 'etatReconditionnement_LIST');
  return list;
}



export const getDashbordTresorerieChart = async (IDCompteBancaire) => {
  let url = 'tresorerie/'+ IDCompteBancaire + '/dashbordTresorerieChart';
  let list = await getList(url, 'dashboardTresoChart_LIST');
  return list;
}


export const getTransactionBancaire = async (IDCompteBancaire, offset, search , transactionBancaire_LIST) => {
  let url = `tresorerie/${IDCompteBancaire}/${offset}/20${(search === '' ? '' : '/'+search)}/transactionBancaire`;

  let list = await getList(url, 'transactionBancaire_LIST', transactionBancaire_LIST);
  return list;
}


export const getTransactionBancaireV2 = async (params, transactionBancaire_LIST) => {

  let list = await getListV2('tresorerie/transactionBancairev2', params,  'transactionBancaire_LIST');
  return transactionBancaire_LIST.concat(list);
}



export const getLigneMobileBack = async (params, ligneMobile_LIST) => {

  let list = await getListV2('telecomBack/ligneMobile', params,  'ligneMobile_LIST');

  list = parseJSONItem(list, 'CDR_MONTH_LIST');

  return ligneMobile_LIST.concat(list);
}



export const getFlotteParam = async (params, flotte_LIST) => {

  console.log('params', params)

  let list = await getListV2('flotte/loadFlotteV2', params,  'flotte_LIST');
  return flotte_LIST.concat(list);
}




export const getLigneArticleForfaitBack = async (params, articleForfait_LIST) => {

  console.log('params', params)

  let list = await getListV2('telecomBack/articleForfait', params,  'articleForfait_LIST');
  return articleForfait_LIST.concat(list);
}


export const getTypeChargeRapprochement = async (params) => {

  let list = await getListV2('tresorerie/loadTypeChargeRapprochement', params, 'typeChargeRapprochement_LIST');
  return list;
}




export const getListModelesToOption = async (iDMarque) => {
  let url = 'profile/'+iDMarque+'/modele';
  return getListToOptions(await getList(url, 'modele_LIST'), 'IDModele', 'LibelleModele');
}


export const getListTypeCaracteristiques = () => {
  let url = '/composant/typeCaracteristique';
  return getList(url, 'typeCaracteristique_LIST');
}


export const getListUnites = () => {
  let url = '/composant/unite';
  return getList(url, 'unite_LIST');
}


export const getListType_CDR = () => {
  let url = '/flotte/type_CDR';
  return getList(url, 'typeCDR_LIST');
}


export const getListInterventions = async (offset, search, IDStatutIntervention, IDSite, IsFacturee, interventionsList = []) => {
  let url = 'profile/'+offset+'/20/'+IDStatutIntervention+'/'+IDSite+ '/' + IsFacturee +(search === '' ? '' : '/'+search)+'/intervention';
  let list = parseJSONItem(await getList(url, 'intervention_LIST'), 'TarifList');

  console.log(list);


  list = parseJSONItem(list, 'CommentaireList');

  //list = parseJSONItem(await getList(url, 'intervention_LIST'), 'TarifList');

  

  return interventionsList.concat(list);
}




export const getListOpportunite = async (params, opportuniteList) => {

  let list = await getListV2('opportunite/opportunite', params,  'opportunite_LIST');
  return opportuniteList.concat(list);
}



export const getListLocations = (pe_IDClient_Societe, search) => {
  let url = 'flotte/'+pe_IDClient_Societe+(search === '' ? '' : '/'+search)+'/location';
  return getList(url, 'location_LIST');
}


export const getListTarifs = (IDPiece, search, isTarifDefaut, offset, idSite, tarifsList) => {

  let url = '/tarif/'+(search === '' ? '0' : search)+'/'+IDPiece+'/'+isTarifDefaut+'/' + idSite + '/' + offset + '/20/tarif';
  return getList(url, 'tarif_LIST', tarifsList);
}


export const getListACommander = (IDSite, search, IDPiece, aCommander_LIST) => {
  let url = `/commandefournisseur/${IDSite}/${IDPiece}${(search === '' ? '' : '/'+search)}/stockACommander`;
  return getList(url, 'aCommander_LIST', aCommander_LIST);
}


export const getListClients = (offset, clientType, search, clientsList) => {
  let url = 'profile/'+clientType+'/'+offset+'/20'+(search === '' ? '' : '/'+search)+'/client';
  return getList(url, 'client_LIST', clientsList);
}


export const getListDevis = (offset, search, status, site, devis_LIST) => {
  let url ='profile/'+offset+'/20/'+status+ '/'+site+(search === '' ? '' : '/'+search)+'/devis';

  return getList(url, 'devis_LIST', devis_LIST);
}


export const getListModeReglementSite = (IDSite, modeReglement_LIST) => {
  let url =`facture/${IDSite}/mode_reglement_site`;
  return getList(url, 'modeReglement_LIST', modeReglement_LIST);
}


export const getQuantiteDispoArticle = (IDArticle, IDSite) => {
  let url = `composant/${IDSite}/${IDArticle}/loadQuantiteDispoArticle`;
  return getList(url, 'stock_LIST', [] );
}


export const getFlotte = (IDClient, pe_ISpreactivee, pe_search, flotte_LIST) => {

  let url = ''
  if(pe_search !== ''){
    url = `profile/${IDClient}/${pe_ISpreactivee}/${pe_search}/flotte`;
  }else{
    url = `profile/${IDClient}/${pe_ISpreactivee}/flotte`;
  }
  // flotte_LIST
  return getList(url, 'flotte_LIST', flotte_LIST);
}



export const getListPrelevement = (offset, IDEtatPrelevement, IDSociete, prelevementList) => {
  var url = 'facture/'+offset+'/20/'+IDEtatPrelevement+'/' + IDSociete + '/prelevement';
  return getList(url, 'prelevement_LIST', prelevementList);
}


export const getListAchat = (offset, IDSite, search, IsPDFOK, TypeAchat, TypeCharge, achatList) => {
  var url = `facture/${offset}/20/${IDSite}/${IsPDFOK}/${TypeAchat}/${TypeCharge}${search === '' ? '' : '/'+search}/achat`;

  return getList(url, 'achat_LIST', achatList);
}


export const getListFactures = async (offset, search, IDEtatFacture, IDTypeFacture, idSite, IDEtatPDFOK, facturesList) => {
  var url = 'facture/'+offset+'/20/'+IDEtatFacture+'/' +IDTypeFacture+'/' + idSite + '/' + IDEtatPDFOK +(search === '' ? '' : '/'+search)+'/facture';
  let list = parseJSONItem(await getList(url, 'facture_LIST'), 'RemiseList');

  list = parseJSONItem(list, 'ComposantList');
  list = parseJSONItem(list, 'ComposantDefecteuxList');

  return facturesList.concat(list);
}




export const getSocieteCompte = async () => {
  let url = '/societe/societe';
  return getList(url, 'societe_LIST');
}







export const getListRemises = (offset, statut, site, modeReglement, remisesList) => {

  let url = 'remise/'+offset+'/20/'+statut+'/'+site+'/'+modeReglement+'/remise';
  return getList(url, 'remise_LIST', remisesList);
}






export const getListSIM = async (IDClient, offset, search, sim_LIST ) => {
  let url = `sim/${IDClient}/${offset}/20${(search === '' ? '' : '/'+search)}/sim`
  return getList(url, 'sim_LIST', sim_LIST);
}


export const getListSiteDashboard = async () => {
  let url = `site/siteDashboard`
  return getList(url, 'site_LIST');
}


export const getListFacturesRemise = (idSite) => {
  let url = '/profile/'+idSite+'/factureRemise';
  return getList(url, 'facture_LIST');
}


export const getListFacturesPrelevement = (IDSociete) => {
  let url = `/facture/${IDSociete}/facturePrelevement`;
  return getList(url, 'facture_LIST');
}


export const getListArticles = (offset, search, IDPiece, IDModele, articlesList) => {
  let url = 'profile/'+IDPiece+'/'+IDModele+'/'+offset+'/20'+(search === '' ? '' : '/'+search)+'/article';
  return getList(url, 'article_LIST', articlesList);
}


export const getListArticlesByModele = (IDModele, IDPiece ) => {
  let url = 'composant/' + IDModele + '/' +  IDPiece  + '/loadArticleByModele';
  return getList(url, 'article_LIST', []);
}


export const getEmailClientExist = (Email) => {
  let url = `client/${Email}/emailClientExist`;
  return getList(url, 'email_LIST', []);
}


export const getAdressesFacturation = async (idClient) => {
  let url = '/client/'+idClient+'/adresseFacturation';
  return getListToOptions(await getList(url, 'adresse_LIST'), 'IDAdresse', 'AdresseSte');
}


export const getAdressesLivraison = async (idClient) => {
  let url = '/client/'+idClient+'/adressesLivraison';
  return getListToOptions(await getList(url, 'adresse_LIST'), 'IDAdresse', 'AdresseSte');
}


export const getAdressesLivraisonByIDAdresseFacturation = async (IDAdresseFacturation) => {
  let url = `client/${IDAdresseFacturation}/adresseLivraisonByIdAdresseFacturation`;
  return getListToOptions(await getList(url, 'adresse_LIST'), 'IDAdresse', 'AdresseSte');
}




export const getListBonsTresoToOptions = async () => {
  let url = 'composant/billetTreso';
  return getListToOptions(await getList(url, 'bon_LIST'), 'IDBonTresorerie', 'LibelleBonTresorerie');
}


export const getListSocietes = async (idClient) => {
  let url = '/profile/'+idClient+'/client_societe';
  return getListToOptions(cleanJSONContent((await getList(url, 'societe_LIST'))[0].treedata), 'key', 'title');
}


export const getListClientsB2BToOptions = async () => {
  let url = 'profile/clientB2B';
  return getListToOptions(await getList(url, 'client_LIST'), 'IDClient', 'Societe');
}


export const getListItemsBoutiquePerso = async (idClient) => {
  let url = '/client/'+idClient+'/0/20/boutique';
  return (await getList(url, 'boutique_LIST'))[0].BoutiqueList;
}


export const getListSAV = async (offset, statut, IDSite, search,  sav_LIST) => {
  if (search == '') {
    search = 'undefined';
  }
  let url = '/sav/'+offset+'/20/'+statut+ '/' + IDSite +'/' + search + '/sav';
  let list = parseJSONItem(await getList(url, 'sav_LIST'), 'CommentaireList');
  list = parseJSONItem(list, 'ComposantList');



  //list = parseJSONItem(await getList(url, 'sav_LIST'), 'SAV_CompList');

  return sav_LIST.concat(list);
}


export const getListPlanningIntervention = async (idSite, date , planning_LIST) => {
  let url = `/intervention/${idSite}/${date}/planning`

  let list = parseJSONItem(await getList(url, 'planning_LIST'), 'planning_LIST');
  return planning_LIST.concat(list);
}


export const getListCharges = async (idSite, date) => {
  let url = '/chargefixe/'+idSite+'/'+date+'/chargeFixe';
  return getList(url, 'charge_LIST');
}


export const getListNavettes = async (offset, status, navettesList) => {
  let url = '/navette/'+offset+'/20/'+status+'/navette';
  let list = parseJSONItem(await getList(url, 'navette_LIST'), 'NavetteLigneList');
  return navettesList.concat(list);
}


export const getListNavettesLight = async (offset, status, navettesList) => {
  let url = '/navette/'+offset+'/20/'+status+'/navetteLight';
  let list = parseJSONItem(await getList(url, 'navette_LIST'), 'NavetteLigneList');
  return navettesList.concat(list);
}


export const getListBonCadeau = async (offset, status, search, bonCadeau_LIST) => {

  let url = `bonCadeau/${status}/${offset}/20/${search}/bonCadeau`;
  let list = parseJSONItem(await getList(url, 'bonCadeau_LIST'), 'bonCadeau_LIST');
  return bonCadeau_LIST.concat(list);
}


export const getListStock = async (offset, idPiece, idSite) => {
  let url = 'profile/stock/' + offset + '/'+ idPiece+'/stock';
  return getList(url, 'stock_LIST');
}


export const getListStockSuiviReco = async (IDSite) => {
  let url = `/stock/${IDSite}/stockSuiviReco`;
  return getList(url, 'stock_LIST');
}

export const getSiteSociete = async (IDSociete) => {
  console.log('IDSociete',IDSociete)
  let url = `/site/${IDSociete}/siteSociete`;
  return getList(url, 'site_LIST');
}


export const getListReconditionnements = async (offset, status, idSite, searchedItem, recList) => {
  let url = '/reconditionnement/' + status + '/' + offset + '/20/'+ idSite +  (searchedItem === '' ? '' : '/'+searchedItem) +   '/reconditionnement';
  let list = parseJSONItem(await getList(url, 'reconditionnement_LIST'), 'ReconditionnementList');
  return recList.concat(list);
}


export const getListReconditionnementsV2 = async (offset, status, idSite, searchedItem, recList) => {
  let url = '/reconditionnement/' + status + '/' + offset + '/20/'+ idSite +  (searchedItem === '' ? '' : '/'+searchedItem) +   '/reconditionnementV2';
  let list = parseJSONItem(await getList(url, 'reconditionnement_LIST'), 'ReconditionnementList');
  return recList.concat(list);
}


export const getListWholesale = async (offset, status, idSite, searchedItem, recList) => {
  let url = '/wholesale/' + status + '/' + offset + '/20/'+ idSite +  (searchedItem === '' ? '' : '/'+searchedItem) +   '/wholesale';

  let list = await getList(url, 'wholeSale_LIST');
  list = parseJSONItem(list, 'TarifClient');

  return recList.concat(list);
}


export const getListDementelement = async (offset, status, idSite, searchedItem, demList) => {

  let url = '/demantelement/' + status + '/' + offset + '/20/'+ idSite +  (searchedItem === '' ? '' : '/'+searchedItem) +   '/demantelement';
  let list = await getList(url, 'demantelement_LIST');
  return demList.concat(list);
}


export const getListInventaire = async (params, inventaireList) => {

  let list = await getListV2('inventaire/inventaire', params,  'inventaire_LIST');
  return inventaireList.concat(list);
}

export const getListRepriseClient = async (params, repriseClientList) => {

  let list = await getListV2('repriseClient/repriseClient', params,  'repriseClient_LIST');
  list = parseJSONItem(list, 'RepriseClientLigne_LIST');

  return repriseClientList.concat(list);
}


export const getListRepriseClientByIDReprise = async (IDRepriseClient) => {
  
  let url = `/repriseClient/${IDRepriseClient}/repriseClientByID`;
  let list = parseJSONItem(await getList(url, 'repriseClient_LIST'), 'RepriseClientLigne_LIST');
  //list = parseJSONItem(list, 'ComposantDefectueuxList');
  return list[0];
}






export const getListencaissementV2 = async (params, encaissementsList) => {

  let list = await getListV2('remise/remiseV2', params,  'remise_LIST');
  return encaissementsList.concat(list);
}




export const getListClientSocieteParrainParam = async (params, clientList) => {

  let list = await getListV2('client/client_SocieteParrain', params,  'client_LIST');

  return clientList.concat(list);

}


export const getListAvoirParam = async (params, avoirList) => {

  let list = await getListV2('facture/avoirLight', params,  'avoir_LIST');

  return avoirList.concat(list);

}





export const getListClientParam = async (params, clientList) => {

  let list = await getListV2('client/client', params,  'client_LIST');

  return clientList.concat(list);
}






export const getListClient_ClientSocieteParam= async (params, clientSocieteList) => {

  let list = await getListV2('client/clientSocieteParam', params,  'client_LIST');
  list = parseJSONItem(list, 'DocumentList');
  return clientSocieteList.concat(list);
}




export const getListFactureClientB2B = async (params, facture_LIST) => {

  let list = await getListV2('factureB2B/loadFactureClientV2', params,  'facture_LIST');
  return facture_LIST.concat(list);
}



export const getListStockSuiviRecoV2 = async (params, itemList) => {

  let list = await getListV2('/reco/suiviReco', params,  'stock_LIST');
  return itemList.concat(list);
}



export const getListStockACommanderV2 = async (params, aCommander_LIST) => {

  let list = await getListV2('commandefournisseur/stockACommander', params,  'aCommander_LIST');

  return aCommander_LIST.concat(list);
}



export const getListEncaissementARapprocher = async (params, remise_List) => {

  let list = await getListV2('remise/remiseARapprocher', params,  'remise_LIST');
  return remise_List.concat(list);
}


export const getListDepenseARapprocher = async (params, achat_List) => {

  let list = await getListV2('achat/depenseARapprocher', params,  'achat_LIST');

  return achat_List.concat(list);
}



export const getListFactureLight = async (params, facture_List) => {

  let list = await getListV2('facture/factureLight', params,  'facture_LIST');

  

  list = parseJSONItem(list, 'FAC_HISTO_LIST');

  return facture_List.concat(list);
}


export const getListFactureEncaissementLight = async (params, facture_List) => {

  let list = await getListV2('facture/loadFactureEncaissementLight', params,  'facture_LIST');

  return facture_List.concat(list);
}







export const getAchatByIDAchat = async (params) => {

  let list = await getListV2('achat/achatByIDAchat', params, 'achat_LIST');

  return list[0];
}




export const getFactureByIDFacture = async (params) => {

  let list = await getListV2('facture/factureByIDFacture', params, 'facture_LIST');

  list = parseJSONItem(list, 'ComposantList');
  list = parseJSONItem(list, 'ComposantDefecteuxList');
  list = parseJSONItem(list, 'RemiseList');


//  list = parseJSONItem(list, 'TarifList');

  return list[0];
}









export const getListCompteComptable = async (params, compteComptable_List) => {

  let list = await getListV2('achat/compteComptable', params,  'compteComptable_LIST');

  return compteComptable_List.concat(list);
}





export const getListAchatV2 = async (params, achat_List) => {

  let list = await getListV2('achat/achatv2', params,  'achat_LIST');

  return achat_List.concat(list);
}



export const getListAchatLight = async (params, achat_List) => {

  let list = await getListV2('achat/achatLight', params,  'achat_LIST');

  return achat_List.concat(list);
}


export const getListFournisseur = async (params, fournisseur_List) => {

  let list = await getListV2('fournisseur/fournisseur', params,  'fournisseur_LIST');

  return fournisseur_List.concat(list);
}






export async function getListReprises(status, offset, idSite, searchedItem, repriseListe) {
  let url = '/reprise/' + status + '/' + offset + '/20/'+ idSite + (searchedItem === '' ? '' : '/'+searchedItem) + '/reprise';

  let list = await getList(url, 'reprise_LIST');
  return repriseListe.concat(list);
}



export async function getListReprisesReglement(status, offset, idSite, searchedItem, IDClient, repriseListe) {
  let url = '/reprise/' + status + '/' + offset + '/20/'+ idSite + '/' + IDClient + (searchedItem === '' ? '' : '/'+searchedItem) + '/repriseReglement';

  let list = await getList(url, 'reprise_LIST');
  return repriseListe.concat(list);
}



export const getListReprisesRapprochement = async (params, reprise_List) => {

  let list = await getListV2('tresorerie/repriseRapprochement', params,  'reprise_LIST');

  return reprise_List.concat(list);
}






/////////////////////////////////////////////////////////////////////////////////
/////******************** LIST ACTIONS PRINT / DOWNLOAD ********************/////
/////////////////////////////////////////////////////////////////////////////////
const downloadFile = (url, name) => {

  const messageIndicator = message.loading('En cours...', 0);

  axios.get(url, {responseType: 'blob'}
  ).then((response) => {
    setTimeout(messageIndicator, 10);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
   }).catch(error => {
     setTimeout(messageIndicator, 10);
     console.log(error)
   });
}

const printFile = (url, name) => {

  const messageIndicator = message.loading('En cours...', 0);
  axios.get(url, {responseType: 'blob'}
  ).then((response) => {
    setTimeout(messageIndicator, 10);
   }).catch(error => {
     setTimeout(messageIndicator, 10);

   });
}


export const downloadFactureAchat = (IDDocument, CodeAchat) => {
  let url = `/document/${IDDocument}/${CodeAchat}/download_document`;
  downloadFile(url, CodeAchat+'.pdf');
}


export const downloadDevis = (codeIntervention) => {
  let url = 'profile/' + codeIntervention + '/download_interventionclient';
  downloadFile(url, codeIntervention+'_devis.pdf');
}

export const downloadCF = (IDCommandeFournisseur) => {
  console.log(IDCommandeFournisseur);

  let url = 'commandeFournisseur/' + IDCommandeFournisseur + '/downloadCommandeFournisseur';
  downloadFile(url, IDCommandeFournisseur+'_CF.pdf');
}

export const downloadReprise = (CodeReprise) => {
  let url = '/reprise/' + CodeReprise + '/downloadReprise';
  downloadFile(url, CodeReprise+'_reprise.pdf');
}

export const downloadMandatPrelevement = (pe_rum, pe_societe, pe_adresse, pe_CP, pe_ville, pe_IBAN, pe_BIC, pe_IDSocieteSelected) => {
  let url = `/client/${pe_rum}/${pe_societe}/${pe_adresse}/${pe_CP}/${pe_ville}/${pe_IBAN}/${pe_BIC}/${pe_IDSocieteSelected}/downloadMandatPrelevement`;
  downloadFile(url, pe_societe+'_mandant_prelevement.pdf');
}

export const printComposantSticker = (GUID) => {
  let url = '/composant/'+GUID+'/printFicheComposant';
  printFile(url);
}


export const downloadContratLigneMobile = (IDLigneMobile) => {

  let url = 'lignemobile/' + IDLigneMobile + '/downloadContrat';
  downloadFile(url, 'contrat_' + IDLigneMobile+'.pdf');
}





export const printBonCadeau = (IDBonCadeau) => {
  let url = '/bonCadeau/'+IDBonCadeau+'/printBonCadeau';
  printFile(url);
}

export const printInterventionSticker = (codeIntervention) => {

  let url = 'profile/' + codeIntervention + '/printFicheIntervention';

  downloadFile(url, codeIntervention+'_sticker.pdf');
}


export const printReconditionnementSticker = (IDReconditionnement) => {
  let url = `reconditionnement/${IDReconditionnement}/printFicheReconditionnement`;
  printFile(url);
}

export const printDemantelementSticker = (IDDemantelement) => {
  let url = `demantelement/${IDDemantelement}/printDemantelement`;
  printFile(url);
}


export const printSAVSticker = (IDSAV) => {
  let url = `sav/${IDSAV}/printFicheSAV`;
  printFile(url);
}


export const printSAVByCodeSticker = (CodeSAV) => {
  let url = `sav/${CodeSAV}/printFicheSAVByCode`;
  printFile(url);
}


export const downloadFacture = (codeFacture) => {
  let url = '/facture/'+codeFacture+'/download_facturebdd';
  downloadFile(url, codeFacture+'.pdf');
}

export const downloadAvoir = (codeAvoir) => {
  let url = '/avoir/'+codeAvoir+'/download_avoirbdd';
  downloadFile(url, codeAvoir+'.pdf');
}

export const downloadExportProPennylane = () => {
  let url = '/client/downloadExportProPennylane';
  downloadFile(url, 'export_pro_pennylane.xlsx');
}

export const downloadExportCDR= (filtreCDR) => {
  let url = `/flotte/${filtreCDR.IDClientSociete}/${filtreCDR.DateDebut}/${filtreCDR.DateFin}/exportCDR`;
  downloadFile(url, 'exportCDR.xlsx');
}



export const downloadExportStatManuel= (filtre) => {
  let url = `/dashboard/${filtre.DateDebutExport}/${filtre.DateFinExport}/exportStatManuel`;
  downloadFile(url, 'exportManuel.xlsx');
}





export const downloadExportWholeSale= () => {
  let url = `/wholesale/exportWholeSale`;
  downloadFile(url, 'exportPhoner.xlsx');
}

export const downloadExportPartPennylane = () => {
  let url = '/client/downloadExportPartPennylane';
  downloadFile(url, 'export_part_pennylane.xlsx');
}

export const downloadDocumentB2B = (guid, libelleDoc) => {
  let url = 'compte/'+guid+'/download_document';
  downloadFile(url, libelleDoc);
}

export const downloadDocumentClient = (guid, libelleDoc, IDClient) => {
  let url = 'client/'+guid+'/'+ IDClient + '/download_document';
  downloadFile(url, libelleDoc);
}

/////////////////////////////////////////////////////////////////////////////////
/////*********************** LIST ACTIONS AXIOS POST ***********************/////
/////////////////////////////////////////////////////////////////////////////////
export async function postAction (url, item, messageContent='') {
  let dataToReturn = [];
  const messageIndicator = message.loading('Modification en cours...', 0);

  await axios.post(url, item
  ).then(({data}) => {
    messageContent = (messageContent === '' ? 'Modification réalisée avec succès' : messageContent);
    setTimeout(messageIndicator, 10);
    message.success(messageContent);

    dataToReturn = data;
  }).catch(function (error) {
    setTimeout(messageIndicator, 10);

    message.error('Erreur lors de la modification');
  });
  return dataToReturn;
}



export async function postActionWithResponse (url, item, messageContent='') {
  let dataToReturn = [];
  const messageIndicator = message.loading('Modification en cours...', 0);

  return new Promise((resolve,reject)=>{


    axios.post(url, item
      ).then(({data}) => {
        messageContent = (messageContent === '' ? 'Modification réalisée avec succès' : messageContent);
        setTimeout(messageIndicator, 10);
        message.success(messageContent);
        resolve(data);
      }).catch(function (error) {
        setTimeout(messageIndicator, 10);
        message.error('Erreur lors de la modification');
        reject()
      });

  })
}




export async function postActionWithoutMessage (url, item, messageContent='') {
  let dataToReturn = [];


  await axios.post(url, item
  ).then(({data}) => {
    dataToReturn = data;
  }).catch(function (error) {

  });
  return dataToReturn;
}

export async function getAction (url, item, messageContent='') {
  let dataToReturn = [];
  const messageIndicator = message.loading('Modification en cours...', 0);

  await axios.get(url, item
  ).then(({data}) => {
    messageContent = (messageContent === '' ? 'Modification réalisée avec succès' : messageContent);
    setTimeout(messageIndicator, 10);
    message.success(messageContent);
    dataToReturn = data;
  }).catch(function (error) {
    setTimeout(messageIndicator, 10);

    message.error('Erreur lors de la modification');
  });
  return dataToReturn;
}



export const saveModificationSIM = async (flotteSIM) => {

  return new Promise((resolve, reject) => {

    const messageIndicator = message.loading('Modification de la ligne en cours..', 0);
    axios.post('/flotteB2B/updateSIM_SWAP_B2B', {Flotte: flotteSIM},
    ).then(({data}) => {

      if(data.success) {
        setTimeout(messageIndicator, 10);
        resolve(true);
      }
      else {
        setTimeout(messageIndicator, 10);
        message.error('Erreur lors de la modification');
        console.log(data.error);
        resolve(true);
      }
    }).catch(function (error){
      setTimeout(messageIndicator, 10);
      console.log("Error****:", error.message);

      resolve(false);
    });

  });


}


export const saveTarifFournisseur = async (tarifFournisseur) => {
  let newTarifFournisseur = null;
  await axios.post('/article/updateTarifFournisseur', {tarifFournisseur}
  ).then(({data}) => {
    if(data.success) {

      message.success("Tarif mis à jour avec succès");
      newTarifFournisseur = data.tarifFournisseur;
    }
    else {
      message.error('Erreur lors de la modification');
      console.log(data.error);
    }
  }).catch(function (error){
    console.log("Error****:", error.message);
  });

  return newTarifFournisseur;
}

export const saveSAV = async (SAV) => {

  await postAction('sav/updateSAV', {SAV});
}


export const saveInventaire = async (inventaire) => {

  await postAction('inventaire/createInventaire', {inventaire});
}


export const saveCommentaireInventaireLigne = async (inventaire) => {

  await postAction('inventaire/updateCommentaireInventaire', {inventaire});
}




export const saveModificationInventaire = async (inventaire) => {

  return await postActionWithoutMessage('inventaire/updateInventaire', {inventaire});
}


export const saveRapprochement = async (rapprochement) => {

  return await postAction('tresorerie/rapprochement', {rapprochement});
}




export const saveTypeCDR = async (TypeCDR) => {

  await postAction('flotte/typeCDR', {TypeCDR});
}


export const demandeResiliation = async (resiliation) => {

  await postAction('flotteB2B/updateResiliationLigne', {resiliation});
}


export const saveFournisseur = async (Fournisseur) => {

  await postAction('fournisseur/fournisseur', {Fournisseur});
}



export const savePrelevement = async (Prelevement) => {
  await postAction('facture/updatePrelevement', {Prelevement});
}

export const saveStartPrelevement = async (Prelevement) => {
  await postAction('facture/startPrelevement', {Prelevement});
}

export const generatePrelevementXML = async (Prelevement) => {
  return await postActionWithResponse('facture/generatePrelevementXML', {Prelevement});
}


export const saveReprise = async (Reprise) => {

  await postAction('reprise/updateReprise', {Reprise});
}

export const saveAchat = async (Achat) => {
  await postAction('achat/updateAchat', {Achat});
}

export const saveWholeSale = async (WholeSale) => {
  return await postAction('wholeSale/updateWholeSale', {WholeSale});
}

export const saveModele = async (Modele) => {
  await postAction('profile/updateModele', {Modele});
}

export const savePiece = async (Piece) => {
  await postAction('piece/updatePiece', {Piece});
}

export const saveCommandeFournisseur = async (CommandeFournisseur) => {
  await postAction('commandefournisseur/updateCommandeFournisseur', {CommandeFournisseur});
}

export const updateEtatCommandeFournisseur = async (IDEtatCommandeFournisseur, IDCommandeFournisseur) => {
  await postAction('commandefournisseur/updateEtatCommandeFournisseur', {IDEtatCommandeFournisseur, IDCommandeFournisseur});
}

export async function saveComposant(Composant) {

  let data = await postAction('/composant/updateComposant', {Composant});
  return data;
}

export const saveIntervention = async (Intervention) => {

  await postAction('profile/intervention', {Intervention});
}

export const saveEmailTelPretIntervention = async (pe_CodeIntervention) => {
  axios.post('intervention/sendEmailTelPretIntervention', {CodeIntervention: pe_CodeIntervention}
  ).then(({data}) => {
    message.success('Intervention modifiée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de l\'intervention');
  });
}

export const saveEmailReprise = async (pe_CodeReprise) => {
  axios.post('reprise/sendEmailReprise', {CodeReprise: pe_CodeReprise}
  ).then(({data}) => {
    message.success('Reprise envoyée par mail');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de l\'envoi du');
  });
}

export const sendTelecomContrat = async (IDLigneMobile, IDDocument, IDClient, GUID, EmailCollaborateur) => {
  axios.post('lignemobile/sendEmailTelecomContrat', {IDLigneMobile: IDLigneMobile, IDDocument : IDDocument, IDClient : IDClient, GUID : GUID, EmailCollaborateur : EmailCollaborateur }
  ).then(({data}) => {
    message.success('Contrat envoyée par mail');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de l\'envoi du mail');
  });
}

export const updateEtatIntervention = async (IDStatutIntervention, codeIntervention) => {
  await axios.post('profile/updateStatutIntervention', {
    IDStatutIntervention: IDStatutIntervention,
    CodeIntervention: codeIntervention}
  ).then(({data}) => {
    message.success('Intervention modifiée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de l\'intervention');
  });
}


export const updateEtatInterventionIDIntervention = async (IDStatutIntervention, IDIntervention) => {
  await axios.post('profile/updateStatutInterventionByIDInter', {
    IDStatutIntervention: IDStatutIntervention,
    IDIntervention: IDIntervention}
  ).then(({data}) => {
    message.success('Intervention modifiée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de l\'intervention');
  });
}



export const updateFacturerIntervention = async (intervention) => {
  await axios.post('profile/updateFacturerIntervention', {
    intervention: intervention}
  ).then(({data}) => {
    message.success('Intervention facturée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la facturation de l\'intervention');
  });
}

export const updateClient = (Client) => {

  axios.post('profile/updateClient', Client
  ).then(({data}) => {
    message.success('Client modifié avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification du client');
  });

}

export const updateClientV2 = async (Client) => {
  await postActionWithResponse('/profile/updateClient', Client);
}

export const updateBonReception = async (BonReception) => {
  await axios.post('commandefournisseur/updateBonReception', {BonReception}
  ).then(({data}) => {
      message.success('Le bon de réception a bien été enregistré');
  }).catch(function (error) {
      console.log('Error****:', error.message);
      message.error('Erreur lors de l\'enregistrement du bon de réception');
  });
}

export const updateEtatDevis = async (status, codeDevis) => {
  await axios.post('profile/updateEtatDevis', {IDStatutDevis: status, CodeDevis: codeDevis}
  ).then(({data}) => {
    message.success('Etat du devis modifié avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de l\'état du devis');
  });
}

export const saveDevis = async (devis) => {
  await axios.post('profile/updateDevis', devis
  ).then(({data}) => {
    message.success('Devis modifié avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification du devis');
  });
}

export const deleteDevis = async (codeDevis) => {
  await axios.post('profile/deleteDevis', {IDDevis: codeDevis}
  ).then(({data}) => {
    message.success('Devis supprimé avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la suppression du devis');
  });
}

export const deleteComposantACommander = async (pe_IDInterventionLigne) => {
  axios.delete(`/composant/${pe_IDInterventionLigne}/removeComposantACommander`,
  ).then(({data}) => {
    if(data.success == true){
      message.success('Article supprimé avec succès');
      return true;
    }else{
     message.error('Erreur lors de la suppression');
     return false;
    }
  }).catch(function (error) {
  });
}


export const deleteAchat = async (pe_IDAchat) => {
  axios.delete(`/achat/${pe_IDAchat}/deleteAchat`,
  ).then(({data}) => {
    if(data.success == 'success'){
      message.success('Achat supprimé avec succès');
      return true;
    }else{
     message.error('Erreur lors de la suppression');
     return false;
    }
  }).catch(function (error) {
  });
}


export const deletePrelevement = async (pe_CodePrelevement) => {
  axios.delete(`/prelevement/${pe_CodePrelevement}/removePrelevement`,
  ).then(({data}) => {
    if(data.success == true){
      message.success('Prélevement supprimé avec succès');
      return true;
    }else{
     message.error('Erreur lors de la suppression du prélevement');
     return false;
    }
  }).catch(function (error) {
  });
}

export const deleteComposant = async (pe_IDComposant) => {
  axios.delete(`/composant/${pe_IDComposant}/removeComposant`,
  ).then(({data}) => {
    if(data.success == true){
      message.success('Composant supprimé avec succès');
      return true;
    }else{
     message.error('Erreur lors de la suppression du composant');
     return false;
    }
  }).catch(function (error) {
  });
}


export const sendComposantToReco = async (pe_IDComposant) => {
  axios.post(`/composant/addToReco`, {'IDComposant' : pe_IDComposant}
  ).then(({data}) => {
    if(data.success == true){
      message.success('Composant ajouté au reconditionnement avec succès');
      return true;
    }else{
     message.error("Erreur lors de l'ajout du composant au reconditionnement");
     return false;
    }
  }).catch(function (error) {
  });
}


export const deleteChargeFixe = async (pe_IDChargeFixe) => {
  axios.delete(`/chargefixe/${pe_IDChargeFixe}/removeChargeFixe`,
  ).then(({data}) => {
    if(data.success == true){
      message.success('Charge fixe supprimée avec succès');
      return true;
    }else{
     message.error('Erreur lors de la suppression de la charge fixe');
     return false;
    }
  }).catch(function (error) {
  });
}



export const updateDashboardSite = (IDSite) => {
  axios.post('dashboard/site', IDSite
  ).then(({data}) => {
    message.success('Dashboard modifié avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification du dashboard');
  });
}



export const updateSocieteSelect = (IDSociete) => {
  axios.post('societe/societeSelect', IDSociete
  ).then(({data}) => {
    message.success('Changement de société');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de la societé');
  });
}



export const sendEmailRappelDevis = async (pe_IDDevis) => {
  await axios.post('/profile/devis/sendEmail', {IDDevis: pe_IDDevis})
  .then(({data}) => {
    message.success('Email de rappel envoyé');
   }).catch(function (error) {
     console.log("Error****:", error.message);
     message.error('Erreur lors de l\'envoi de l\'email');
   });
}


export const saveFacture = async (facture) => {
  await axios.post('profile/facture', {Facture: facture}
  ).then(({data}) => {
    message.success('Facture modifiée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de la facture');
  });
}


export const generateFacture = async (codeFacture, sendEmailBool) => {

  console.log(codeFacture, sendEmailBool)
  const messageIndicator = message.loading('Génération de la facture en cours...', 0);
  await axios.post('profile/create_facture', {
    codeFacture: codeFacture,
    IsEmailClientSent: sendEmailBool}
  ).then(({data}) => {
    setTimeout(messageIndicator, 10);
    message.success('Facture '+codeFacture+' générée avec succès');
  }).catch(function (error) {
    setTimeout(messageIndicator, 10);
    console.log("Error****:", error.message);
  });
}


export const saveRemise = async (remise) => {

  await axios.post('profile/remise', {Remise: remise}
  ).then(({data}) => {
    message.success('Remise modifiée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification de la remise');
  })
}

export const deleteRemise = async (Remise) => {
  axios.delete(`/remise/${Remise.IDRemise}/deleteRemise`,
  ).then(({data}) => {

  }).catch(function (error) {
  });
}

export const saveArticle = async (article) => {

  await postAction('profile/updateArticle', article);
}

export const updateBoutiqueClient = async (BoutiqueList) => {
  await postAction('/client/updateBoutiqueClient', BoutiqueList);
}

export const updateEtatSAV = async (IDEtatSAV, IDSAV) => {
  await postAction('/sav/updateStatutSAV', {IDEtatSAV, IDSAV});
}

export const saveCharge = async (charge) => {
  await postAction('/chargefixe/chargeFixe', {charge});
}

export const updateEtatNavette = async (IDEtatNavette, IDNavette) => {
  await postAction('/navette/updateStatutNavette', {IDEtatNavette, IDNavette});
}

export const saveNavette = async (navette) => {
  await postAction('/navette/navette', {navette});
}

export const saveReconditionnement = async (reconditionnement) => {
  await postAction('/reconditionnement/reconditionnement', {reconditionnement});
}

export const saveDemantelement = async (demantelement) => {
  await postAction('/demantelement/demantelement', {demantelement});
}

export const updateEtatReconditionnement = async (IDEtat, IDReconditionnement) => {
  await postAction('/reconditionnement/updateStatutReconditionnement', {IDEtat, IDReconditionnement});
}


export const updateEtatWholesale = async (IDEtat, IDWholesale) => {

  await postAction('/wholesale/updateWholesaleStatut', {IDEtat, IDWholesale});
}


export const updateEtatDemantelement = async (IDEtat, IDDemantelement) => {
  await postAction('/demantelement/updateDemantelementStatut', {IDEtat, IDDemantelement});
}


export const updateEtatInventaire = async (IDEtatInventaire, IDInventaire) => {
  await postAction('/inventaire/updateInventaireStatut', {IDEtatInventaire, IDInventaire});
}


export const saveBonCadeau = async (bonCadeau) => {
  await postAction('/bonCadeau/bonCadeau', {bonCadeau});
}


export const getsimBySerial = async (simSerial) => {
  let url = `/telecomBack/${simSerial}/simBySerial`;
  return getList(url, 'sim_LIST');
}


export const exportComptableVente = async (exportComptable) => {

  return await postAction('/exportComptable/exportVente', {exportComptable});
}


export const exportB2BDetailConsommation = async (exportParam) => {

  return await postAction('/factureB2B/createExportDetailConsommation', {exportParam});
}





export const exportInvoicePENNYLANE = async (exportData) => {
  await postAction('/exportComptablePennylane/exportInvoice', {exportData});
}

export const exportInvoiceSupplierPENNYLANE = async (exportData) => {
  await postAction('/exportComptablePennylane/exportInvoiceSupplier', {exportData});
}




/////////////////////////////////////////////////////////////////////////////////
/////****************************** B2B PART ******************************/////
/////////////////////////////////////////////////////////////////////////////////
export const getMonCompte = async () => {
  let url = '/compte/compte';
  return getList(url, 'compte');
}


export const getDashboardRSE = async () => {
  let url = '/compte/rse';
  return getList(url, 'dashboardRSE_LIST');
}

export const getEmployeB2B = async () => {
  let url = '/flotteB2B/loadEmploye';
  return getList(url, 'employe_LIST');
}


export const getComposantHistorique = async (IDComposant) => {
  let url = '/composant/' + IDComposant +  '/composantHistoriqueList';
  return getList(url, 'historique_LIST');
}

export const getEtatIntervention = async () => {
  let url = 'intervention/etatIntervention';
  return getList(url, 'etatIntervention_LIST');
}


export const getDocuments = async () => {
  let url = '/compte/document';
  return getList(url, 'document_LIST');
}

export const getBoutique = async (boutiqueType = 0) => {
  //boutiqueType = 0 si générale ou 1 si personnalisée
  let url = 'profile/' + boutiqueType + '/telephoneB2B';
  return getList(url, 'article_LIST');
}

export const getCatalogue = async (boutiqueType = 0, searchedItem = '', IDPiece = -1) => {
  let url = 'catalogue/' + boutiqueType + '/' + IDPiece +  (searchedItem === '' ? '' : '/'+searchedItem) +  '/catalogueB2B';
  return getList(url, 'article_LIST');
}

export const updatePanierAchatB2B = async (Panier) => {
  await postActionWithoutMessage('profile/panier', {Panier});
}

export const updateArticleFavorisB2B = async (Article) => {
  await postAction('catalogue/updateArticleFavorisB2B', {Article});
}

export const getPanierAchatB2B = async () => {
  let url = 'profile/panierAchat';
  return (await getList(url, 'panier_LIST'))[0];
}

export const validateCommandeB2B = async (codeIntervention) => {
  await axios.post('profile/validerPanier', {CodeIntervention: codeIntervention})
  .then(({data}) => {
    message.success('Le panier est validé');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la validation du panier');
  });
}

export const updateQuantiteB2B = async (Panier) => {
  await postActionWithoutMessage('profile/panier', {Panier});
}

export const deleteTarifB2B = async (LigneIntervention) => {
  await postAction('profile/deleteLigneIntervention', {LigneIntervention}, 'L\'article a bien été supprimé du panier');
}

export const getAdressesFacturationB2B = async () => {
  let url = '/factureB2B/loadAdresseFacturation';
  return getListToOptions(await getList(url, 'adresse_LIST'), 'IDAdresse', 'AdresseSte');
}

export const getAdressesLivraisonB2B = async (IDAdresseFacturation) => {
  let url = `/factureB2B/${IDAdresseFacturation}/loadAdresseLivraison`;
  return getListToOptions(await getList(url, 'adresse_LIST'), 'IDAdresse', 'AdresseSte');
}

export const getOptionsMobile = async (IDTypeOption) => {
  let url = '/flotte/'+IDTypeOption+'/loadOptionMobile';
  return getListToOptions(await getList(url, 'optionMobile_LIST'), 'IDOptionMobile', 'LibelleOptionMobile');
}

export const getOptionsMobileB2B = async (IDTypeOption) => {
  let url = '/flotteB2B/'+IDTypeOption+'/loadOptionMobile';
  return getListToOptions(await getList(url, 'optionMobile_LIST'), 'LibelleOptionMobile', 'LibelleOptionMobileAffichage');
}

export const getClientsSociete = async () => {
  let url = '/flotteB2B/client_societe';
  return getListToOptions(cleanJSONContent((await getList(url, 'societe_LIST'))[0].treedata), 'key', 'title');
}

export const getListB2BParc = async (params, parcList) => {

  let list = await getListV2('flotteB2B/parc', params,  'parc_LIST');
  return parcList.concat(list);
}
